import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { DataGrid } from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import { Grid, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Swal from "sweetalert2";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { HighlightOffOutlined } from "@material-ui/icons";
const variableEntorno = process.env.REACT_APP_API_URL;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0 0 3px 3px;
  background: #fff;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;
const ContentHeader = styled.div`
  height: 10%;
  width: 100%;
  background: #424242;
  display: flex;
  color: white;
  align-items: center;
`;

const ContentBody = styled.div`
  height: 88%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.p`
  margin: 0 0.5em;
  font-weight: bold;
`;
const BoxCrear = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleDialog = styled.div`
  color: white;
  background: #332e33;
  font-weight: bold;
`;

const BoxTable = styled.div`
  width: 100%;
  height: 150px;
  @media (min-width: 1920px) and (max-width: 3000px) {
    height: 500px;
  }
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * This is a React component that manages the creation, deletion, and display of areas of specialist.
 * @returns The component `Especialistas` is being returned.
 */
const Especialistas = () => {
  const [datos, setDatos] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [dataCrear, setDataCrear] = useState({
    nombre: "",
  });
  const [dataActualizacion, setDataActualizacion] = useState(true);
  const [refetch, setRefecth] = React.useState(0);

  /** Open the form to add a new area of specialist*/
  const handleClickOpen = () => {
    setOpen(true);
  };

  /** Close the form to add a new area of specialist*/
  const handleClose = () => {
    setOpen(false);
  };

  /**A useEffect hook that is triggered when the "refetch" state changes. It sends a GET request to update the specialist list.
   */
  React.useEffect(() => {
    axios.get(`${variableEntorno}api/especialistas`).then((result) => {
      setDatos(result.data);
    });
  }, [refetch]);

  /**
   * GET request to obtain the list of specialists (areas)
   */
  const getGestion = async () => {
    try {
      const resNoc = await axios.get(`${variableEntorno}api/especialistas`);
      //console.log(resNoc.data);

      setDatos(resNoc.data);
      //console.log(resNoc.data);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * POST request to add a new area of specialist
   */
  const CrearServicio = async () => {
    try {
      const res = await axios.post(`${variableEntorno}api/especialistas`, {
        nombre: dataCrear.nombre,
      });
      setOpen(false);
      Swal.fire({
        title: "Gracias",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error al crear ponte en contacto con el administrador ",
        icon: "error",
      });
      console.log(error.response);
    }
    setDataCrear({
      nombre: "",
    });
    setDataActualizacion(!dataActualizacion);
  };

  /**
   * checks if "dataCrear" has the requiered fields to enable the button
   * @returns Returning a boolean value indicating  if "dataCrear" has the requiered fields.
   */
  const validarBoton = () => {
    const noValido = dataCrear.nombre === "";
    return noValido;
  };

  /**
   * DELETE request to delete an area of specialist according the id
   * @param {*} id id of the area
   */
  const deleteEspecialista = async (id) => {
    try {
      const resp = await axios.delete(`${variableEntorno}api/especialistas`, {
        data: { id: id },
      });
      setRefecth(refetch + 1);

      Swal.fire({
        title: "Area eliminada con exito",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error al eliminar el area",
        icon: "error",
      });
      console.log(error.response);
    }
  };

  useEffect(() => {
    getGestion();
  }, [dataActualizacion]);

  useEffect(() => {
    getGestion();
  }, []);

  /**
   * Columns of the Area of specialist table
   */
  const columns = [
    {
      field: "id",
      headerName: "Indicativo",
      width: 140,
    },
    {
      field: "nombre",
      headerName: "Nombre Area",
      width: 200,
    },

    {
      field: "fecha",
      headerName: "Fecha ingreso",
      width: 180,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        const ElimiarEspecialista = (e) => {
          e.stopPropagation();
          console.log(params);
          deleteEspecialista(params.row.id);
        };
        return (
          <Button
            variant="text"
            color="primary"
            startIcon={<HighlightOffOutlined />}
            onClick={ElimiarEspecialista}
          >
            ELIMINAR
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Container>
        <ContentHeader>
          <Title>GESTION AREA DISPONIBILE</Title>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            INGRESO AREA
          </Button>
        </ContentHeader>
        <BoxCrear>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
          >
            <TitleDialog>
              <DialogTitle background="black" id="scroll-dialog-title">
                CREAR AREA
              </DialogTitle>
            </TitleDialog>

            <DialogContent style={{ cursor: "pointer" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    value={dataCrear.nombre}
                    label="Nombre Area."
                    onChange={(e) =>
                      setDataCrear({
                        ...dataCrear,
                        nombre: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => CrearServicio(dataCrear.nombre)}
                disabled={validarBoton()}
              >
                Crear Disponibilidad
              </Button>
              <Button onClick={handleClose} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </BoxCrear>

        <div style={{ height: 800, width: "100%" }}>
          <DataGrid rows={datos} columns={columns} pageSize={100} />
        </div>
      </Container>
    </div>
  );
};

export default Especialistas;
