import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContext } from "../context/auth";
import DashContextProvider from "../context/dashboard";
import Dashboard from "./Dashboard";
import Login from "./auth/Login";
import Disponibilidad from "./principal/Disponibilidad";

function App() {
  const [state] = useContext(AuthContext);
  return (
    <DashContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={!state.auth ? <Login></Login> : <Dashboard />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </DashContextProvider>
  );
}

export default App;
