import Box from "@mui/material/Box";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Swal from "sweetalert2";
import { Button } from "@material-ui/core";
const variableEntorno = process.env.REACT_APP_API_URL;

/**
 * The MatrizRACI function returns a table component with columns and data, including a delete button
 * that sends a DELETE request to the server to remove a record from the table.
 * @returns The MatrizRACI component is being returned, which renders a DataGrid table with columns for
 * the RACI matrix and includes a delete button for each row that sends a DELETE request to the server
 * to delete the corresponding record. The component also receives data and a change function as props.
 */
export default function MatrizRACI({ data, change }) {
  /**
   * Colums for the table of the RACI matrix
   */
  const columns = [
    {
      field: "ambiente",
      headerName: "Ambiente",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "falla",
      headerName: "Falla",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nombre",
      headerName: "Especialista",
      width: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "medio_comunicacion",
      headerName: "Canal",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "resultado_falla",
      headerName: "Remediación",
      type: "boolean",
      width: 160,
      headerAlign: "center",
      valueGetter: (params) => {
        return params.row.resultado_falla === "true";
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const eliminarT = (e) => {
          e.stopPropagation();
          eliminar(params.row.id);
        };
        return (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutlineIcon />}
            onClick={eliminarT}
          >
            Eliminar
          </Button>
        );
      },
    },
  ];
  /**
   * DELETE request to delete a record of the matrix according the id
   * @param {*} id id to delete
   */
  const eliminar = async (id) => {
    try {
      change(true);
      const res = await axios.delete(
        `${variableEntorno}api/modeloComunicacion/${id}`,
        {}
      );

      Swal.fire({
        title: "Eliminado correctamente.",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error al eliminar.",
        icon: "error",
      });
      console.log(error.response);
    }
    change(false);
  };
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <h1>Matriz RACI</h1>
      <DataGrid rows={data} columns={columns} pageSize={8} />
    </Box>
  );
}
