import { TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
const variableEntorno = process.env.REACT_APP_API_URL;

/**
 * This is a React component that renders a form to add a new service, which includes fields for event
 * type, IP address, and service name, and a button to submit the form.
 * @returns A form component that allows the user to input data for a new service and submit it using a
 * POST request. The form includes fields for the type of event, IP address, and service
 * name, as well as a button to submit the data. The component also makes a GET request to obtain a
 * list of roles for specialists.
 */
const FormRelServicios = ({ change }) => {
  const [data, setData] = useState({});
  const [listaRoles, setListaRoles] = useState([]);
  // const channels = [
  //   { name: "Correo", value: "email" },
  //   { name: "Whatsapp", value: "whatsapp" },
  //   { name: "Llamada TTS", value: "tts" },
  //   { name: "Mensaje de texto SMS", value: "sms" },
  //   { name: "Llamada", value: "call" },
  // ];
  useEffect(() => {
    getRoles();
  }, []);

  /**
   * GET request to obtain the list of areas of specialists
   */
  const getRoles = async () => {
    try {
      const res = await axios.get(`${variableEntorno}api/especialistas`);
      setListaRoles(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Adds a new service using a POST request to the API.
   * Validates that the 'event', 'ip', and 'service' fields are not empty. If the entered IP address is invalid,
   * displays an error message.
   */
  const agregarServicio = async () => {
    if (
      data.event?.length > 0 &&
      data.ip?.length > 0 &&
      data.service?.length > 0
    ) {
      if (
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          data.ip
        )
      ) {
        try {
          change(true);
          const res = await axios.post(`${variableEntorno}api/servicios`, {
            event: data.event,
            service: data.service,
            ip: data.ip,
          });
          setData({});
          Swal.fire({
            title: "Servicio agregado con exito",
            icon: "success",
          });
          change(false);
        } catch (error) {
          Swal.fire({
            title: "Ocurrió un error",
            icon: "error",
          });
          console.log(error.response);
        }
      } else {
        Swal.fire({
          title: "Debe ingresar una ip valida",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Los campos no pueden estar vacios",
        icon: "error",
      });
      return;
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="80vh"
    >
      <h1>Ingresar Servicio</h1>
      <div>
        <TextField
          id="eventField"
          label="Tipo evento"
          variant="outlined"
          value={data.event ?? ""}
          onChange={(e) =>
            setData({
              ...data,
              event: e.target.value,
            })
          }
        />
      </div>
      <div>
        <TextField
          id="ipField"
          label="Dirección ip"
          variant="outlined"
          value={data.ip ?? ""}
          onChange={(e) =>
            setData({
              ...data,
              ip: e.target.value,
            })
          }
        />
      </div>
      <div>
        <TextField
          id="serviceField"
          label="Nombre del servicio"
          variant="outlined"
          value={data.service ?? ""}
          onChange={(e) =>
            setData({
              ...data,
              service: e.target.value,
            })
          }
        />
      </div>

      <div>
        <Button variant="contained" color="secondary" onClick={agregarServicio}>
          Agregar
        </Button>
      </div>
    </Box>
  );
};

export default FormRelServicios;
