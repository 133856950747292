import axios from "axios";
/**
 * This function sets or deletes the x-auth-token header in axios based on the provided token.
 * @param token - The token parameter is a string that represents an authentication token. It is used
 * to set or delete the "x-auth-token" header in the axios default headers.
 */
const tokenAuth = (token) => {
  if (token) {
    axios.defaults.headers.common["x-auth-token"] = token;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
  }
};

export default tokenAuth;
