import { Grid } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import FormRACI from "./FormRACI";
import MatrizRACI from "./MatrizRACI";
const variableEntorno = process.env.REACT_APP_API_URL;
/**
 * This is a React component that fetches and displays a RACI matrix and a form to add new items to the
 * matrix.
 * @returns The code is returning a React functional component named RACI. This component renders a
 * Grid container with two child components: FormRACI and MatrizRACI. It also makes a GET request to obtain data for the MatrizRACI component.
 */
const RACI = () => {
  const [dataRaci, setDataRaci] = useState([]);
  const [change, setChange] = useState(false);
  /**
   * GET request to obtain the list RACI matrix
   */
  const getMatriz = async () => {
    try {
      const res = await axios.get(`${variableEntorno}api/modeloComunicacion`);
      setDataRaci(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMatriz();
  }, [change]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <FormRACI change={setChange} />
        </Grid>

        <Grid item xs={7}>
          <MatrizRACI data={dataRaci} change={setChange} />
        </Grid>
      </Grid>
    </>
  );
};

export default RACI;
