import { TextField, MenuItem, Button } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
const variableEntorno = process.env.REACT_APP_API_URL;

/**
 * The `FormRACI` function is a React component that renders a form for adding a new RACI record, with
 * fields for environment, fault, specialist role, communication channels, and automatic resolution,
 * and a button to submit the form.
 * @returns A functional component called `FormRACI` that renders a form with input fields for
 * "Ambiente", "Falla", "Rol del especialista", "Medio de comunicación", and "Resolución automatica".
 * It also includes two buttons, one to add a new record and another to clear the input fields. The
 * component uses state hooks to manage the form data and the list of
 */
const FormRACI = ({ change }) => {
  const [data, setData] = useState({});
  const [listaRoles, setListaRoles] = useState([]);
  const channels = [
    { name: "Correo", value: "email" },
    { name: "Whatsapp", value: "whatsapp" },
    { name: "Llamada TTS", value: "tts" },
    { name: "Mensaje de texto SMS", value: "sms" },
    { name: "Llamada", value: "call" },
  ];
  useEffect(() => {
    getRoles();
  }, []);
  /**
   * GET request to obtain the list of areas of specialists
   */
  const getRoles = async () => {
    try {
      const res = await axios.get(`${variableEntorno}api/especialistas`);
      setListaRoles(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  /* The `agregarRACI` function is called when the "Add" button is pressed. It first checks if all required fields (environment, fault, specialist, channels and resolutionA) have values. If all fields have values, it sends a POST request to add a new RACI record with the provided data. If the request succeeds it displays a success message using the `Swal.fire` function. If the request fails, it displays an error message using the `Swal.fire` function and logs the error response to the console. error response to the console. If any of the required fields is empty, it displays an error message using the `Swal.fire` function and returns without sending the request.  */
  const agregarRACI = async () => {
    if (
      data.ambiente?.length > 0 &&
      data.falla?.length > 0 &&
      data.especialista > 0 &&
      data.canales?.length > 0 &&
      data.resolucionA !== undefined
    ) {
      try {
        change(true);
        const res = await axios.post(
          `${variableEntorno}api/modeloComunicacion`,
          {
            ambiente: data.ambiente,
            falla: data.falla,
            especialista: data.especialista,
            canales: data.canales,
            resolucionA: data.resolucionA,
          }
        );
        Swal.fire({
          title: "Registro agregado con exito",
          icon: "success",
        });
        change(false);
      } catch (error) {
        Swal.fire({
          title: "Ocurrió un error",
          icon: "error",
        });
        console.log(error.response);
      }
    } else {
      Swal.fire({
        title: "Los campos no pueden estar vacios",
        icon: "error",
      });
      return;
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="80vh"
    >
      <h1>Ingresar RACI</h1>
      <div>
        <TextField
          id="ambientField"
          label="Ambiente"
          variant="outlined"
          value={data.ambiente ?? ""}
          onChange={(e) =>
            setData({
              ...data,
              ambiente: e.target.value,
            })
          }
        />
        <TextField
          id="failField"
          label="Falla"
          variant="outlined"
          value={data.falla ?? ""}
          onChange={(e) =>
            setData({
              ...data,
              falla: e.target.value,
            })
          }
        />
      </div>
      <div>
        <TextField
          id="rolField"
          select
          label="Rol del especialista"
          value={data?.especialista ?? ""}
          onChange={(e) =>
            setData({
              ...data,
              especialista: e.target.value,
            })
          }
        >
          {listaRoles?.map((rol) => {
            return (
              <MenuItem value={rol.id} key={rol.id}>
                {rol.nombre}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          id="channelField"
          select
          label="Medio de comunicación"
          SelectProps={{
            multiple: true,
          }}
          value={data?.canales ?? []}
          onChange={(e) =>
            setData({
              ...data,
              canales: e.target.value,
            })
          }
        >
          {channels?.map((channel) => {
            return (
              <MenuItem value={channel.value} key={channel.value}>
                {channel.name}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
      <div>
        <TextField
          id="resolucionField"
          select
          label="Resolución automatica"
          value={data?.resolucionA ?? ""}
          onChange={(e) =>
            setData({
              ...data,
              resolucionA: e.target.value,
            })
          }
        >
          <MenuItem value={true}>Exitosa</MenuItem>
          <MenuItem value={false}>Fallida</MenuItem>
        </TextField>
      </div>
      <div>
        <Button variant="contained" color="secondary" onClick={agregarRACI}>
          Agregar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "1em" }}
          onClick={() => setData({})}
        >
          Limpiar campos
        </Button>
      </div>
    </Box>
  );
};

export default FormRACI;
