import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { AuthContext } from "../../context/auth";
import tokenAuth from "./tokenAuth";
import Logo from "../../assets/images/Logo_Yeapp.svg";
import Button from "../globales/Button";
import Alerta from "./Alerta";
import axios from "axios";
import Swal from "sweetalert2";
const variableEntorno = process.env.REACT_APP_API_URL;
const Container = styled.div`
  display: flex;
  height: 100vh;
`;
const ContentLeft = styled.div`
  width: 40%;
  background: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImgLogo = styled.img`
  width: 55%;
`;

const ContentRight = styled.div`
  width: 60%;
  background: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const SubContentRigth = styled.div`
  width: 100%;
  height: 94%;
`;
const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10em 0 0 6em;
  width: 40%; //Define el ancho del formulario
`;
const TextWelcome = styled.p`
  font-size: calc(1vw + 0.9em);
  color: #3c3c3c;
  margin: 2em 0 0.3em 0;
  cursor: default;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(1vh + 0.8em);
  }
`;
const SpanVerde = styled.span`
  color: #98e01b;
  font-weight: bold;
`;
const SpanRosado = styled.span`
  color: #e61667;
  font-weight: bold;
`;
const TextInfo = styled.p`
  font-size: calc(1vw + 0.1em);
  color: #3c3c3c;
  margin: 0 0 1em 0;
  cursor: default;
`;
const BoxInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em 0;
`;
const Label = styled.label`
  color: #3c3c3c;
  margin: 0.3em 0;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(0.5vh + 0.7em);
  }
`;
const BoxField = styled.div`
  //width: 100%;
  border: 1px solid #666666;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  padding-left: 0.2em;
  :hover {
    border: 1px solid #1a1a1a;
  }
`;
const Input = styled.input`
  font-size: calc(1vw + 0.4em);
  color: #3c3c3c;
  padding: 0.5em 0.5em 0.5em 0.2em;
  border: none;
  outline: none;
  width: 90%;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(1vh + 0.8em);
  }
`;
const BoxButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.5em 0 1em 0;
  @media (min-width: 300px) and (max-width: 425px) {
    margin: 1em 0;
  }
`;

const Login = () => {
  const [auth, authDispatch] = useContext(AuthContext);
  const [datos, setDatos] = useState({
    user: "",
    password: "",
  });

  useEffect(() => {
    usuarioAutenticado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setDatos({ ...datos, [e.target.name]: e.target.value });
  };

  /**
   * Checks if the user and password fields are not empty, and if they are, it displays an alert message.
   * @param e - Contains information about the event, such as the target element, the type of event, and any data associated with the event.
   * @returns If the condition `datos.user.trim() === "" || datos.password.trim() === ""` is true, the
   * function will return and not execute the `iniciarSesion()` function. If the condition is false, the
   * `iniciarSesion() will be execute`
   */
  const onSubmit = (e) => {
    e.preventDefault();
    if (datos.user.trim() === "" || datos.password.trim() === "") {
      mostrarAlerta("Todos los campos son obligatorios!");
      return;
    }
    iniciarSesion();
    setDatos({ ...datos, password: "" });
  };

  //Funciones Context
  function mostrarAlerta(msg) {
    authDispatch({
      type: "ALERTA",
      payload: { mostrar: true, msg: msg },
    });
    setTimeout(() => {
      authDispatch({
        type: "ALERTA",
        payload: { mostrar: false, msg: "" },
      });
    }, 3500);
  }

  /**
   * This function attempts to log in a user by sending a POST request to a server and storing the
   * resulting token in local storage, while handling any errors that may occur.
   */
  const iniciarSesion = async () => {
    try {
      const respuesta = await axios.post(`${variableEntorno}api/authdo`, datos);
      //console.log(respuesta.data);
      localStorage.setItem("token", respuesta.data.token);
      usuarioAutenticado();
    } catch (error) {
      Swal.fire({
        title: "No se puede acceder al servidor",
        icon: "error",
      });
      // mostrarAlerta(error.response.data.msg); // Se deberia controlar este error, ya que si no hay servidor backend crashea al hacer POST
    }
  };

  /**
   * This function checks if there is a token in local storage and authenticates the user if there is,
   * otherwise it sets the authentication state to false.
   */
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      tokenAuth(token);
    }
    try {
      const respuesta = await axios.get(`${variableEntorno}api/authdo`);
      authDispatch({
        type: "USER",
        payload: respuesta.data,
      });
      authDispatch({
        type: "AUTH",
        payload: true,
      });
      //console.log(respuesta.data);
    } catch (error) {
      localStorage.removeItem("token");
      //console.log(error.response);
      authDispatch({
        type: "AUTH",
        payload: false,
      });
    }
  };
  return (
    <Container>
      <ContentLeft>
        <ImgLogo src={Logo} />
      </ContentLeft>

      <ContentRight>
        <SubContentRigth>
          <ContentForm>
            <TextWelcome>
              Bienvenid@ a<SpanRosado> YEAPP</SpanRosado>
              <SpanVerde>DO!</SpanVerde>
            </TextWelcome>
            <TextInfo>Ingresa los datos de tu cuenta</TextInfo>
            {auth.alerta.mostrar ? <Alerta text={auth.alerta.msg} /> : null}
            <form style={{ width: "100%" }} onSubmit={onSubmit}>
              <BoxInput>
                <Label>USUARIO</Label>
                <BoxField>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="6%"
                    fill="#3C3C3C"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z" />
                  </svg>
                  <Input
                    type="text"
                    name="user"
                    onChange={onChange}
                    value={datos.user}
                  />
                </BoxField>
              </BoxInput>

              <BoxInput>
                <Label>CONTRASEÑA</Label>
                <BoxField>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="6%"
                    fill="#3C3C3C"
                  >
                    <g fill="none">
                      <path d="M0 0h24v24H0V0z" />
                      <path d="M0 0h24v24H0V0z" opacity=".87" />
                    </g>
                    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z" />
                  </svg>
                  <Input
                    type="password"
                    name="password"
                    onChange={onChange}
                    value={datos.password}
                  />
                </BoxField>
              </BoxInput>

              <BoxButtons>
                <Button
                  type="submit"
                  name={"Ingresar"}
                  color={"rgba(255, 255, 255, 1)"}
                  colorBorder={"#1C1C1C"}
                  bg={"#1C1C1C"}
                  padding={"0.5em 0.7em"}
                  fontSize={"calc(1vw + 0.3em)"}
                  bgHover={"#17191A"}
                />
              </BoxButtons>
            </form>
          </ContentForm>
        </SubContentRigth>
      </ContentRight>
    </Container>
  );
};

export default Login;
