import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth";
import { DashContext } from "../context/dashboard";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import UpperBar from "./UpperBar";
import tokenAuth from "./auth/tokenAuth";
import Settings from "./Settings";
import axios from "axios";
import Sidebar from "./SideBar";
import Icon from "./globales/Icon";
import Administracion from "./administracion/Administracion";
import Especialistas from "./principal/Especialistas";
import { Grid } from "@mui/material";
import Schedule from "./schedule/Schedule";
import ScheduleWeek from "./schedule/ScheduleWeek";
import RACI from "./RACI/RACI";
import Disponibilidad from "./principal/Disponibilidad";
import RelServicios from "./RelacionamientoServicios/RelServicios";

const variableEntorno = process.env.REACT_APP_API_URL;

const Container = styled.div`
  display: grid;
  grid-template-columns: fit-content(100%) repeat(11, 1fr);
  grid-template-rows: fit-content(100%) repeat(9, 1fr);
  height: 100vh;
  overflow-y: auto;
  @media (min-width: 300px) and (max-width: 425px) {
    grid-template-columns: fit-content(100%) repeat(3, 1fr);
  }
`;
const Header = styled.header`
  grid-column: ${(props) => (props.showSidebar ? "4 / 13" : "1 / 13")};
  grid-row: 1 / 1;
`;

const Boxrow = styled.div`
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.5em 0 0;
  background: #1a1a1a;
`;

const Content = styled.div`
  grid-column: ${(props) => (props.showSidebar ? "4 / 13" : "1 / 13")};
  grid-row: 2 / 11;
  padding: 0.5em 1em;
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: scroll;
  box-sizing: border-box;
  background: #fff;
  ::-webkit-scrollbar {
    background: #fafafa;
    border-radius: 10px;
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    background: #fafafa;
    border-radius: 10px;
  }
  @media (min-width: 300px) and (max-width: 425px) {
    grid-column: 1 / 4;
    padding: 0.5em;
  }
`;

const Comp = ({ tasks }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Especialistas />
        </Grid>

        <Grid item xs={6}>
          <Schedule tasks={tasks} />
        </Grid>
      </Grid>
    </>
  );
};

function Dashboard() {
  const [auth, authDispatch] = useContext(AuthContext);
  const [state, dispatch] = useContext(DashContext);
  const [tasks, setTasks] = useState([]);
  const [loadingSchedule, setLoadinSchedule] = useState(true);

  function hideSettings() {
    dispatch({
      type: "SETTINGS",
      payload: false,
    });
  }

  const getGestion = async () => {
    try {
      const resNoc = await axios.get(`${variableEntorno}api/turnos`);
      //console.log(resNoc.data);

      let tasksList = [];
      let i = 0;
      resNoc.data?.forEach((element) => {
        const horaInicio = element.inicio?.split(":");
        const horaFin = [
          (Number(horaInicio[0]) + Number(element.duracion)).toString(),
          horaInicio[1],
        ];
        for (let index = 1; index <= 5; index++) {
          let fecha = new Date();
          fecha.setDate(fecha.getDate() + (index - fecha.getDay()));
          let startDate, endDate;
          startDate = new Date(
            fecha.getFullYear(),
            fecha.getMonth(),
            fecha.getDate(),
            horaInicio[0],
            horaInicio[1]
          );
          endDate = new Date(
            fecha.getFullYear(),
            fecha.getMonth(),
            fecha.getDate(),
            horaFin[0],
            horaFin[1]
          );
          tasksList.push({
            title: `Turno ${element.nombre}`,
            id: i,
            startDate: startDate,

            endDate: endDate,
            priorityId: element.id_area,
          });
          i++;
        }
      });
      setTasks(tasksList);
      setLoadinSchedule(false);
    } catch (error) {
      console.log(error);
    }
  };

  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      tokenAuth(token);
    }
    try {
      //La siguiente peticion es necesaria para que no se cambie el auth a true por la consola del navegador
      const respuesta = await axios.get(`${variableEntorno}api/authdo`);
      authDispatch({
        type: "USER",
        payload: respuesta.data,
      });
      //console.log(respuesta);
    } catch (error) {
      localStorage.removeItem("token");
      //console.log(error.response);

      authDispatch({
        type: "AUTH",
        payload: false,
      });
      authDispatch({
        type: "ALERTA",
        payload: { mostrar: true, msg: "No te pases de list@ conmigo >:(" },
      });
    }
  };
  useEffect(() => {
    usuarioAutenticado();
    getGestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Header showSidebar={state.sidebar}>
        <UpperBar />
      </Header>
      {state.sidebar ? (
        <>
          <Boxrow>
            <Icon
              width={"35px"}
              height={"35px"}
              color={"#ffff"}
              children={
                <path d="M3,18h13v-2H3V18z M3,13h10v-2H3V13z M3,6v2h13V6H3z M21,15.59L17.42,12L21,8.41L19.59,7l-5,5l5,5L21,15.59z" />
              }
              transform={state.sidebar ? undefined : "true"}
              onClick={() => dispatch({ type: "SIDEBAR", payload: false })}
            />
          </Boxrow>
          <Sidebar />
        </>
      ) : null}
      {state.settings ? <Settings hideSettings={hideSettings} /> : null}

      <Content
        showSidebar={state.sidebar}
        onClick={() => {
          dispatch({ type: "SETTINGS", payload: false });
        }}
      >
        <Routes>
          <Route path="admin" element={<Administracion />}></Route>
          <Route path="raci" element={<RACI />}></Route>
          <Route path="turnos" element={<Disponibilidad />}></Route>
          <Route path="servicios" element={<RelServicios />}></Route>
          <Route
            path="schedule"
            element={<ScheduleWeek tasks={tasks} />}
          ></Route>

          <Route path="especialista" element={<Comp tasks={tasks} />}></Route>
          <Route
            path="/*"
            element={
              !loadingSchedule ? <Comp tasks={tasks} /> : <Especialistas />
            }
          ></Route>
        </Routes>
      </Content>
    </Container>
  );
}

export default Dashboard;
