import React, { useContext } from "react";
import { AuthContext } from "../context/auth";
import { DashContext } from "../context/dashboard";
import styled from "styled-components";
import LogoTP from "../assets/images/LogoTPBlanco.svg";
import Icon from "./globales/Icon";
import Avatar from "react-avatar";
import ReorderIcon from "@material-ui/icons/Reorder";

const Container = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: #1a1a1a;
  padding: 0 1em;
  color: white;
`;
const BoxLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-items: center;
`;
const TextLogo = styled.p`
  font-size: calc(0.5vw + 0.9em);
  color: #fff;
  font-weight: bold;
  margin: 0;
  cursor: default;
  align-items: center;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(1vh + 0.8em);
  }
`;
const SpanGO = styled.span`
  color: #98e01b;
  font-weight: bold;
  align-items: center;
`;
const ImgTP = styled.img`
  height: 15px;
`;
const BoxPerfil = styled.div`
  display: flex;
  align-items: center;
`;
const TextUser = styled.p`
  color: white;
  margin: 0 0.5em 0 0.2em;
  cursor: default;
`;
const BoxRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) => (props.bgHover ? "#666666" : "#332e30")};
  :hover {
    background: #666666;
  }
`;

const UpperBar = () => {
  const [auth] = useContext(AuthContext);
  const [state, dispatch] = useContext(DashContext);

  return (
    <Container>
      <BoxLogo>
        <TextLogo>
          {state.sidebar ? null : (
            <ReorderIcon
              style={{ fontSize: 30 }}
              onClick={() => dispatch({ type: "SIDEBAR", payload: true })}
            />
          )}
          YEAPP<SpanGO>DO!</SpanGO>{" "}
        </TextLogo>
      </BoxLogo>
      <BoxPerfil>
        <Avatar
          color={"#666666"}
          name={auth.user.user}
          size="32"
          round={true}
          fgColor={"white"}
        />
        <TextUser>{auth.user.user}</TextUser>
        <BoxRow
          onClick={() =>
            dispatch({
              type: "SETTINGS",
              payload: !state.settings,
            })
          }
          bgHover={state.settings}
        >
          <Icon
            width={"40px"}
            height={"40px"}
            color={"#fff"}
            children={
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
            }
            transform={state.settings ? "true" : undefined}
          />
        </BoxRow>
      </BoxPerfil>
    </Container>
  );
};

export default UpperBar;
