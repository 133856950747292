import Box from "@mui/material/Box";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import axios from "axios";
import Swal from "sweetalert2";

const variableEntorno = process.env.REACT_APP_API_URL;

export default function ListaServicios({ data, change }) {
  /*  Colums for the table of */
  const columns = [
    {
      field: "nombre_servicio",
      headerName: "Nombre servicio",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tipo_evento",
      headerName: "Tipo evento",
      width: 175,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ip",
      headerName: "Dirección Ip",
      width: 175,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const eliminarT = (e) => {
          e.stopPropagation();
          eliminar(params.row.id);
        };
        return (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DeleteOutlineIcon />}
            onClick={eliminarT}
          >
            Eliminar
          </Button>
        );
      },
    },
  ];
  const eliminar = async (id) => {
    try {
      change(true);
      const res = await axios.delete(
        `${variableEntorno}api/servicios/${id}`,
        {}
      );

      Swal.fire({
        title: "Servicio eliminado",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error al eliminar el servicio",
        icon: "error",
      });
      console.log(error.response);
    }
    change(false);
  };
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <h1>Servicios</h1>
      <DataGrid rows={data} columns={columns} pageSize={8} />
    </Box>
  );
}
