import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { DataGrid } from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Swal from "sweetalert2";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
const variableEntorno = process.env.REACT_APP_API_URL;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0 0 3px 3px;
  background: #fff;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;
const ContentHeader = styled.div`
  height: 10%;
  width: 100%;
  background: #424242;
  display: flex;
  color: white;
  align-items: center;
`;

const ContentBody = styled.div`
  height: 88%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.p`
  margin: 0 0.5em;
  font-weight: bold;
`;
const BoxCrear = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleDialog = styled.div`
  color: white;
  background: #332e33;
  font-weight: bold;
`;

const BoxTable = styled.div`
  width: 100%;
  height: 150px;
  @media (min-width: 1920px) and (max-width: 3000px) {
    height: 500px;
  }
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * This is a React component that manages the availability of specialists and their shifts, allowing
 * users to add, edit, and delete shifts, as well as view a table of all shifts.
 * @param BasicModal - The BasicModal parameter is likely a component or function that renders a basic
 * modal dialog box. It is not used within the Disponibilidad component, but it may be used elsewhere
 * in the codebase.
 * @returns A React component called "Disponibilidad" is being returned.
 */
const Disponibilidad = (BasicModal) => {
  const [datos, setDatos] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openE, setOpenE] = React.useState(false);
  const [dataCrear, setDataCrear] = useState({
    nombre: "",
    area: "",
    telefono: "",
    correo: "",
    turno: "",
  });

  const [dataEditar, setDataEditar] = useState({
    nombre: "",
    area: "",
    telefono: "",
    correo: "",
    turno: "",
  });
  const [listaEspecialista, setListasEspecialista] = useState([{ nombre: "" }]);

  /** Columns for the table of disponibility */
  const columns = [
    {
      field: "nombre",
      headerName: "Nombre Disponible",
      width: 200,
    },
    {
      field: "area",
      headerName: "Area",
      width: 200,
    },
    {
      field: "telefono",
      headerName: "Telefono",
      width: 200,
    },
    {
      field: "correo",
      headerName: "Correo",
      width: 200,
    },
    {
      field: "turno",
      headerName: "Turno",
      width: 200,
    },
    {
      field: "fecha",
      headerName: "Fecha y hora ingreso",
      width: 250,
    },
    {
      field: "hora_salida",
      headerName: "Fecha y hora Salida",
      width: 250,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 250,
      sortable: false,
      renderCell: (params) => {
        /**
         * Calls the "liberarTurno" function with a parameter.
         * @param e - The event object that triggered the function.
         */
        const eliminarTurno = (e) => {
          e.stopPropagation();
          liberarTurno(params.row.id);
        };
        /**
         * fill dataEditar with the information of the row selected and opens the form of the shifts for update it
         * @param {*} e The event object that triggered the function.
         */
        const editarTurno = (e) => {
          e.stopPropagation();
          setDataEditar({
            ...dataEditar,
            id: params.row.id,
            nombre: params.row.nombre,
            area: params.row.area,
            telefono: params.row.telefono,
            correo: params.row.correo,
            turno: params.row.turno,
          });
          setOpenE(true);
          console.log(params.row);
        };

        return (
          <>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={editarTurno}
            >
              Editar
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DeleteOutlineIcon />}
              onClick={eliminarTurno}
            >
              Eliminar
            </Button>
          </>
        );
      },
    },
  ];

  /**
   * GET request to obtain the list of the specialists
   */
  const obtenerListas = async () => {
    try {
      const respuesta = await axios.get(
        `${variableEntorno}api/especialistas`,
        {}
      );
      console.log(respuesta);
      setListasEspecialista(respuesta.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    obtenerListas();
  }, []);

  /** Open the form to add an availability*/
  const handleClickOpen = () => {
    setOpen(true);
  };
  /** Close the form to add an availability*/
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * GET request to obtain the list of shifts
   */
  const getTurnos = async () => {
    try {
      const resNoc = await axios.get(`${variableEntorno}api/turnos`);
      //console.log(resNoc.data);

      setDatos(resNoc.data);
      //console.log(resNoc.data);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * This function checks if the phone number has 10 digits and if the email is in a valid format before send a POST request.
   * @returns If the length of the phone number is not 10 digits or if the email is not in a valid
   * format, a message will be displayed using the Swal library and the function will return without
   * further execution.
   */
  const CrearDisponibilidad = async () => {
    if (dataCrear.telefono.length !== 10) {
      Swal.fire({
        title: "El número de telefono debe tener 10 dígitos",
        icon: "error",
      });
      return;
    }
    if (
      !dataCrear.correo.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      Swal.fire({
        title: "El correo debe estar en un formato valido",
        icon: "error",
      });
      return;
    }

    try {
      /**
       * POST request to add a new availability
       */
      const res = await axios.post(`${variableEntorno}api/turnos`, {
        nombre: dataCrear.nombre,
        area: dataCrear.area,
        telefono: dataCrear.telefono,
        correo: dataCrear.correo,
        turno: dataCrear.turno,
      });

      setOpen(false);
      Swal.fire({
        title: "Gracias por tu disponibilidad",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title:
          "Error al crear tu disponibilidad ponte en contacto con el administrador ",
        icon: "error",
      });
      console.log(error.response);
    }
    setDataCrear({
      nombre: "",
      area: "",
      telefono: "",
      correo: "",
      turno: "",
    });
  };

  /**
   * This function edits availability data and validates phone number and email format before sending a
   * PUT request to the server.
   * @returns If the length of the phone number is not 10 digits or if the email is not in a valid
   * format, a message will be displayed using the Swal library and the function will return without
   * further execution.
   */
  const EditarDisponibilidad = async () => {
    if (dataEditar.telefono.length !== 10) {
      Swal.fire({
        title: "El número de telefono debe tener 10 dígitos",
        icon: "error",
      });
      return;
    }
    if (
      !dataEditar.correo.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      Swal.fire({
        title: "El correo debe estar en un formato valido",
        icon: "error",
      });
      return;
    }
    try {
      const res = await axios.put(
        `${variableEntorno}api/turnos/${dataEditar.id}`,
        {
          nombre: dataEditar.nombre,
          area: dataEditar.area,
          telefono: dataEditar.telefono,
          correo: dataEditar.correo,
          turno: dataEditar.turno,
        }
      );
      console.log(res);

      setOpenE(false);
      Swal.fire({
        title: "Disponibilidad editada.",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title:
          "Error al editar tu disponibilidad ponte en contacto con el administrador ",
        icon: "error",
      });
      console.log(error.response);
    }
    setDataEditar({
      nombre: "",
      area: "",
      telefono: "",
      correo: "",
      turno: "",
    });
  };

  /**
   * PUT request to delete all shifts
   */
  const liberarTurnos = async () => {
    try {
      const res = await axios.put(`${variableEntorno}api/turnos`, {});

      setOpen(false);
      Swal.fire({
        title: "Porfavor registrar el nuevo ingeniero de disponibilidad",
        icon: "success",
      });
      getTurnos();
    } catch (error) {
      Swal.fire({
        title: "Error al liberar turnos",
        icon: "error",
      });
      console.log(error.response);
    }
  };

  /**
   * PUT request to delete the shift according the id
   */
  const liberarTurno = async (id) => {
    try {
      const res = await axios.delete(`${variableEntorno}api/turnos/${id}`, {});

      Swal.fire({
        title: "Turno liberado",
        icon: "success",
      });
      getTurnos();
    } catch (error) {
      Swal.fire({
        title: "Error al liberar turno",
        icon: "error",
      });
      console.log(error.response);
    }
  };
  /**
   * checks if "dataCrear" has the requiered fields to enable the button
   * @returns Returning a boolean value indicating  if "dataCrear" has the requiered fields.
   */
  const validarBoton = () => {
    const noValido =
      dataCrear.nombre === "" ||
      dataCrear.area === "" ||
      dataCrear.telefono === "" ||
      dataCrear.correo === "" ||
      dataCrear.turno === "";
    return noValido;
  };

  /**
   * checks if "dataEditar" has the requiered fields to enable the button
   * @returns Returning a boolean value indicating  if "dataEditar" has the requiered fields.
   */
  const validarBotonE = () => {
    const noValido =
      dataEditar.nombre === "" ||
      dataEditar.area === "" ||
      dataEditar.telefono === "" ||
      dataEditar.correo === "" ||
      dataEditar.turno === "";
    return noValido;
  };

  useEffect(() => {
    getTurnos();
  }, [open, openE]);

  return (
    <div>
      <Container>
        <ContentHeader>
          <Title>GESTION INGENIERO DE DISPONIBILIDAD</Title>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            INGRESO DISPONIBILIDAD
          </Button>

          <Link to="/login">
            <button>Administracion</button>
          </Link>
        </ContentHeader>
        <BoxCrear>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
          >
            <TitleDialog>
              <DialogTitle background="black" id="scroll-dialog-title">
                CREAR DISPONIBILIDAD
              </DialogTitle>
            </TitleDialog>

            <DialogContent style={{ cursor: "pointer" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    value={dataCrear.nombre}
                    label="Nombre Ingeniero Disponibilidad."
                    onChange={(e) =>
                      setDataCrear({
                        ...dataCrear,
                        nombre: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    select
                    required
                    fullWidth
                    defaultValue=""
                    label="Area"
                    onChange={(e) =>
                      setDataCrear({
                        ...dataCrear,
                        area: e.target.value,
                      })
                    }
                  >
                    {listaEspecialista.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.nombre}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    value={dataCrear.telefono}
                    label="Telefono"
                    onChange={(e) =>
                      setDataCrear({
                        ...dataCrear,
                        telefono: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    type="email"
                    value={dataCrear.correo}
                    label="Correo"
                    onChange={(e) =>
                      setDataCrear({
                        ...dataCrear,
                        correo: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    select
                    fullWidth
                    value={dataCrear.turno}
                    defaultValue=""
                    label="Turno"
                    onChange={(e) =>
                      setDataCrear({
                        ...dataCrear,
                        turno: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={"Mañana"}>Mañana</MenuItem>
                    <MenuItem value={"Tarde"}>Tarde</MenuItem>
                    <MenuItem value={"Noche"}>Noche</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => CrearDisponibilidad(dataCrear.nombre)}
                disabled={validarBoton()}
              >
                Crear Disponibilidad
              </Button>
              <Button onClick={handleClose} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </BoxCrear>

        <BoxCrear>
          <Dialog
            open={openE}
            onClose={() => {
              setOpenE(false);
            }}
            aria-labelledby="customized-dialog-title"
          >
            <TitleDialog>
              <DialogTitle background="black" id="scroll-dialog-title">
                EDITAR DISPONIBILIDAD
              </DialogTitle>
            </TitleDialog>

            <DialogContent style={{ cursor: "pointer" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    value={dataEditar.nombre}
                    label="Nombre Ingeniero Disponibilidad."
                    onChange={(e) =>
                      setDataEditar({
                        ...dataEditar,
                        nombre: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    select
                    required
                    fullWidth
                    defaultValue=""
                    label="Area"
                    value={dataEditar.area}
                    onChange={(e) =>
                      setDataEditar({
                        ...dataEditar,
                        area: e.target.value,
                      })
                    }
                  >
                    {listaEspecialista.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.nombre}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    value={dataEditar.telefono}
                    label="Telefono"
                    onChange={(e) =>
                      setDataEditar({
                        ...dataEditar,
                        telefono: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    value={dataEditar.correo}
                    label="Correo"
                    onChange={(e) =>
                      setDataEditar({
                        ...dataEditar,
                        correo: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    select
                    fullWidth
                    value={dataEditar.turno}
                    defaultValue=""
                    label="Turno"
                    onChange={(e) =>
                      setDataEditar({
                        ...dataEditar,
                        turno: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={"Mañana"}>Mañana</MenuItem>
                    <MenuItem value={"Tarde"}>Tarde</MenuItem>
                    <MenuItem value={"Noche"}>Noche</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={EditarDisponibilidad}
                disabled={validarBotonE()}
              >
                Editar Disponibilidad
              </Button>
              <Button onClick={() => setOpenE(false)} color="secondary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </BoxCrear>
        <Button color="secondary" onClick={() => liberarTurnos()}>
          liberar disponibilidad
        </Button>
        <ContentBody>
          <div
            style={{ height: 600, width: "100%" }}
            open={open}
            onClose={handleClose}
          >
            <DataGrid rows={datos} columns={columns} pageSize={100} />
          </div>
        </ContentBody>
      </Container>
    </div>
  );
};

export default Disponibilidad;
