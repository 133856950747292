import React from "react";
import styled from "styled-components";

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b71c1c;
  padding: 0.5em;
  width: 100%;
  border-radius: 5px;
  @media (min-width: 300px) and (max-width: 425px) {
    width: 90%;
    margin-top: 0.5em;
  }
`;
const Text = styled.p`
  font-size: calc(1vw + 0.1em);
  font-weight: bold;
  color: #fff;
  margin: 0;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(1vh + 0.5em);
  }
`;

const Alerta = (props) => {
  return (
    <Content>
      <Text>{props.text}</Text>
    </Content>
  );
};

export default Alerta;
