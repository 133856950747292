import React, { useReducer, createContext } from "react";
export const AuthContext = createContext({});

let initialState = {
  token: localStorage.getItem("token"),
  auth: null,
  user: {},
  error: false,
  alerta: { mostrar: false, msg: "" },
};

let authReducer = (state, action) => {
  switch (action.type) {
    case "AUTH":
      return { ...state, auth: action.payload };
    case "USER":
      return { ...state, user: action.payload };
    case "ERROR":
      return { ...state, error: action.payload };
    case "ALERTA":
      return { ...state, alerta: action.payload };
    default:
      return initialState;
  }
};

const AuthProvider = ({ children }) => {
  const [state, authdispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={[state, authdispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
