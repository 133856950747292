import React, { useContext, useState } from "react";
import styled from "styled-components";
import Img1 from "../../assets/images/people.svg";
import Img2 from "../../assets/images/connect.svg";
import Paper from "@material-ui/core/Paper";
import { CircularProgress, Grid, MenuItem, TextField } from "@material-ui/core";
import axios from "axios";
import Swal from "sweetalert2";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import clsx from "clsx";
import { CheckCircleOutline, HighlightOffOutlined } from "@material-ui/icons";
import { AuthContext } from "../../context/auth";
import { useNavigate } from "react-router-dom";
const variableEntorno = process.env.REACT_APP_API_URL;

const BoxTitle = styled.div`
  display: flex;
  justify-content: center;
`;
const TextInfo = styled.p`
  font-size: calc(1vw + 0.1em);
  color: #3c3c3c;
  margin: 0 0 0.5em 0;
  cursor: default;
`;
const BoxCrear = styled.div`
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
`;

const rol = [
  {
    value: "Agente",
    label: "Agente",
  },
  {
    value: "Administrador",
    label: "Administrador",
  },
];
const estado = [
  {
    value: "ACTIVO",
    label: "ACTIVO",
  },
  {
    value: "INACTIVO",
    label: "INACTIVO",
  },
];

const TitleDialog = styled.div`
  color: white;
  background: #332e33;
  font-weight: bold;
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * This is a React component that allows an administrator to create, edit, and disable users in a
 * system, using a form and a table to display user information.
 * @returns A React component called "Administracion" is being returned.
 */
export default function Administracion() {
  const navigate = useNavigate();
  const [auth] = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [refetch, setRefecth] = React.useState(0);
  const [open2, setOpen2] = React.useState(false);
  const [listaUsuarios, setListaUsuarios] = React.useState([]);
  const [dataCrear, setDataCrear] = useState({
    usuario: "",
    password: "",
    rol: "",
    nombres_apellidos: "",
    estado: "",
  });
  const [dataEditar, setDataEditar] = useState({
    identificacion: "",
    usuario: "",
    password: "",
    rol: "",
    nombres_apellidos: "",
    estado: "",
  });

  /** Verify if the rol is Administrador, else send to login page*/
  React.useEffect(() => {
    if (auth.user.rol !== "Administrador") {
      navigate("/");
    }
  }, []);

  /** GET request to get the list of users of YeappDo */
  React.useEffect(() => {
    axios.get(`${variableEntorno}api/usuarios/listDo`).then((result) => {
      setListaUsuarios(result.data);
    });
  }, [refetch]);

  /** When editing the user, fill the form fields according to the user ID selected  */
  React.useEffect(() => {
    const UsuarioEditar = listaUsuarios.filter((u) => {
      return u.identificacion === dataEditar.identificacion;
    });
    setDataEditar({
      ...dataEditar,
      nombres_apellidos: UsuarioEditar[0]?.nombres_apellidos || "",
      password: UsuarioEditar[0]?.password || "",
      usuario: UsuarioEditar[0]?.usuario || "",
      estado: UsuarioEditar[0]?.estado || "",
      rol: UsuarioEditar[0]?.rol || "",
    });
  }, [dataEditar.identificacion]);

  /** Open the form to create an user */
  const handleClickOpen = () => {
    setOpen(true);
  };
  /** Close the form to create an user */
  const handleClose = () => {
    setOpen(false);
  };
  /** Open the form to edit an user */
  const handleClickOpenUpdate = () => {
    setOpen2(true);
  };
  /** Close the form to edit an user */
  const handleCloseUpdate = () => {
    setOpen2(false);
  };

  /**
   * POSR request to create an user
   */
  const CrearUsuario = async () => {
    try {
      const res = await axios.post(`${variableEntorno}api/usuarios/do`, {
        usuario: dataCrear.usuario,
        password: dataCrear.password,
        rol: dataCrear.rol,
        nombres_apellidos: dataCrear.nombres_apellidos,
        estado: dataCrear.estado,
        identificacion: dataCrear.identificacion,
      });

      setOpen(false);
      Swal.fire({
        title: "Usuario agregado con exito",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error al crear el usuario",
        icon: "error",
      });
      console.log(error.response);
    }
    setDataCrear({
      usuario: "",
      password: "",
      rol: "",
      nombres_apellidos: "",
      email: "",
      estado: "",
      identificacion: "",
    });
  };
  /**
   * PUT request to change the user status
   * @param {String} nombreUser - user
   * @param {String} estado - status
   */
  const DeshabilitarUsuario = async (nombreUser, estado) => {
    try {
      const resp = await axios.put(`${variableEntorno}api/usuarios/do`, {
        nombre: nombreUser,
        estado: estado,
      });
      setRefecth(refetch + 1);
      setOpen2(false);
      Swal.fire({
        title: "Usuario inhabilitado con exito",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error al crear el usuario",
        icon: "error",
      });
      console.log(error.response);
    }
    setDataEditar({
      nombre: "",
    });
  };
  /**
   * PUT request to edit the user information
   * @param {Object} data - updated user information to change
   */
  const EditarUsuario = async (data) => {
    try {
      const resp = await axios.put(
        `${variableEntorno}api/usuarios/editdo/${data.identificacion}`,
        {
          body: data,
        }
      );
      setRefecth(refetch + 1);
      setOpen2(false);
      Swal.fire({
        title: "Usuario editado con exito",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error al Editar el usuario",
        icon: "error",
      });
      console.log(error.response);
    }
    setDataEditar({
      nombres_apellidos: "",
    });
  };

  /**
   * checks if "dataCrear" has the requiered fields to enable the button
   * @returns Returning a boolean value indicating  if "dataCrear" has the requiered fields.
   */
  const validarBoton = () => {
    const noValido =
      dataCrear.usuario === "" ||
      dataCrear.password === "" ||
      dataCrear.rol === "" ||
      dataCrear.nombres_apellidos === "" ||
      dataCrear.estado === "" ||
      dataCrear.identificacion === "";
    return noValido;
  };

  /**
   * checks if "dataEditar" has the requiered fields to enable the button
   * @returns Returning a boolean value indicating  if "dataEditar" has the requiered fields.
   */
  const validarBotonInhabilitar = () => {
    const noValidoEditar = dataEditar.nombres_apellidos === "";
    return noValidoEditar;
  };

  /**
   * colums for the user table
   */
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "usuario",
      headerName: "Usuario",
      width: 120,
    },

    {
      field: "nombres_apellidos",
      headerName: "Nombres",
      width: 200,
    },
    {
      field: "rol",
      headerName: "Rol",
      width: 125,
    },
    {
      field: "estado",
      headerName: "Estado",
      width: 150,
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }
        return clsx("super-app", {
          active: params.value === "ACTIVO",
          inactive: params.value === "INACTIVO",
        });
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 160,
      sortable: false,
      renderCell: (params) => {
        /**
         * This function toggles the state of a user between active and inactive and displays a button
         * to either enable or disable the user.
         * @param e - The event object that triggered the function (in this case, a click event on a
         * button).
         */
        const editarUsuario = (e) => {
          e.stopPropagation();
          DeshabilitarUsuario(params.row.usuario, params.row.estado);
        };
        return (
          <>
            {params.row.estado === "ACTIVO" ? (
              <Button
                variant="text"
                color="primary"
                startIcon={<HighlightOffOutlined />}
                onClick={editarUsuario}
              >
                DESHABILITAR
              </Button>
            ) : (
              <Button
                variant="text"
                color="secondary"
                startIcon={<CheckCircleOutline />}
                onClick={editarUsuario}
              >
                HABILITAR
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid justifyContent="center" container direction="row" spacing={6}>
        <Grid item>
          <Paper style={{ width: "450px", height: "350px" }}>
            <BoxTitle>
              <TextInfo>
                <h1>CREAR USUARIO</h1>
              </TextInfo>
            </BoxTitle>

            <BoxCrear>
              <Img src={Img1} onClick={handleClickOpen} />

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
              >
                <TitleDialog>
                  <DialogTitle background="black" id="scroll-dialog-title">
                    CREAR USUARIO
                  </DialogTitle>
                </TitleDialog>

                <DialogContent style={{ cursor: "pointer" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        value={dataCrear.usuario}
                        label="USUARIO"
                        onChange={(e) =>
                          setDataCrear({
                            ...dataCrear,
                            usuario: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        value={dataCrear.password}
                        label="PASSWORD"
                        onChange={(e) =>
                          setDataCrear({
                            ...dataCrear,
                            password: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        size="small"
                        fullWidth
                        select
                        value={dataCrear.rol}
                        label="ROL"
                        onChange={(e) =>
                          setDataCrear({
                            ...dataCrear,
                            rol: e.target.value,
                          })
                        }
                      >
                        {rol.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        value={dataCrear.nombres_apellidos}
                        label="NOMBRES Y APELLIDOS"
                        onChange={(e) =>
                          setDataCrear({
                            ...dataCrear,
                            nombres_apellidos: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="number"
                        required
                        value={dataCrear.identificacion}
                        label="IDENTIFICACION"
                        onChange={(e) =>
                          setDataCrear({
                            ...dataCrear,
                            identificacion: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        size="small"
                        fullWidth
                        select
                        value={dataCrear.estado}
                        label="ESTADO"
                        onChange={(e) =>
                          setDataCrear({
                            ...dataCrear,
                            estado: e.target.value,
                          })
                        }
                      >
                        {estado.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ margin: "auto", width: "-webkit-fill-available" }}
                    onClick={() => CrearUsuario(dataCrear.usuario)}
                    disabled={validarBoton()}
                  >
                    Crear usuario
                  </Button>
                  <Button
                    sx={{ margin: "auto", width: "-webkit-fill-available" }}
                    onClick={handleClose}
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>
            </BoxCrear>
          </Paper>
        </Grid>
        <Grid item>
          <Paper style={{ width: "450px", height: "350px" }}>
            <BoxTitle>
              <TextInfo>
                <h1>EDITAR USUARIO</h1>
              </TextInfo>
            </BoxTitle>
            <BoxCrear>
              <Img src={Img2} onClick={handleClickOpenUpdate} />

              <Dialog
                open={open2}
                onClose={handleCloseUpdate}
                fullWidth
                aria-labelledby="customized-dialog-title"
              >
                <TitleDialog>
                  <DialogTitle background="black" id="scroll-dialog-title">
                    EDITAR USUARIO
                  </DialogTitle>
                </TitleDialog>

                <DialogContent style={{ cursor: "pointer" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        value={dataEditar.usuario}
                        label="SELECCIONE EL USUARIO QUE SE VA A EDITAR"
                        onChange={(e) =>
                          setDataEditar({
                            ...dataEditar,
                            identificacion: e.target.value,
                          })
                        }
                      >
                        {listaUsuarios?.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.identificacion}>
                              {item.usuario}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        value={dataEditar.usuario}
                        label="USUARIO"
                        onChange={(e) =>
                          setDataEditar({
                            ...dataEditar,
                            usuario: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        size="small"
                        fullWidth
                        select
                        value={dataEditar.rol}
                        label="ROL"
                        onChange={(e) =>
                          setDataEditar({
                            ...dataEditar,
                            rol: e.target.value,
                          })
                        }
                      >
                        {rol.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        value={dataEditar.nombres_apellidos}
                        label="NOMBRES Y APELLIDOS"
                        onChange={(e) =>
                          setDataEditar({
                            ...dataEditar,
                            nombres_apellidos: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    sx={{ margin: "auto", width: "-webkit-fill-available" }}
                    variant="contained"
                    color="primary"
                    onClick={() => EditarUsuario(dataEditar)}
                    disabled={validarBotonInhabilitar()}
                  >
                    Editar Usuario
                  </Button>
                  <Button
                    sx={{ margin: "auto", width: "-webkit-fill-available" }}
                    onClick={handleCloseUpdate}
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>
            </BoxCrear>
          </Paper>
        </Grid>
      </Grid>
      {/* Tabla */}
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          mt: "5%",
          height: 300,
          width: "100%",
          "& .super-app-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.3)",
          },
          "& .super-app.inactive": {
            color: "#e5007c",
          },
        }}
      >
        {!listaUsuarios.length > 0 ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <DataGrid
              density={"compact"}
              autoHeight
              rows={listaUsuarios || []}
              pageSize={10}
              columns={columns}
              disableSelectionOnClick
              components={{ Toolbar: GridToolbar }}
              getRowId={(row) => row.id}
              // onSelectionModelChange={(e) => setEventsID(e)}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
