import React, { useContext } from "react";
import { AuthContext } from "../context/auth";
import styled, { keyframes } from "styled-components";
import Icon from "./globales/Icon";
import axios from "axios";

const zoomIn = keyframes`
from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
50% {
      opacity: 1;
    }
`;

const Container = styled.div`
  background: white;
  display: flex;
  grid-column: 11 / 13;
  grid-row: 2;
  flex-direction: column;
  border-radius: 10px;
  //border: 1.3px solid #bdbdbd;
  margin: 1em 1.5em 0 0;
  padding: 5px;
  z-index: 100;
  transition: 0.3s ease-in-out;
  //box-shadow: 1px 6px 8px -5px rgba(0, 0, 0, 0.23);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  text-align: center;
  animation: ${zoomIn} 0.2s ease-in-out;
  box-sizing: border-box;
  @media (min-width: 320px) and (max-width: 425px) {
    grid-column: 1 / 5;
    margin: 8px 16px 0 16px;
  }
  @media (min-width: 426px) and (max-width: 800px) {
    grid-column: 4 / 9;
    margin: 8px 16px 0 16px;
  }
`;

const BoxItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0.5em 0;
  //cursor: pointer;
`;
const TextItem = styled.p`
  font-size: calc(0.5vw + 0.5em);
  color: #3c3c3c;
  font-weight: bold;
  margin: 0;
  cursor: default;
`;

/*
const TextLink = styled(Link)`
  font-size: calc(1vw + 0.5em);
  font-weight: 400;
  margin: 8px;
  color: white;
  text-decoration: none;
  @media (min-width: 320px) and (max-width: 425px) {
    font-size: calc(2vh + 0.625rem);
  }
  @media (min-width: 426px) and (max-width: 800px) {
    font-size: calc(1vh + 0.925rem);
  }
`;*/

const Line = styled.hr`
  color: #3e4042;
  margin: 4px 0px 4px 0;
  width: 100%;
`;

const BoxExit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0.2rem;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background: #eeeeee;
  }
`;
const TextExit = styled.p`
  font-size: calc(0.5vw + 0.5em);
  color: #3c3c3c;
  //font-weight: bold;
  margin: 0 0.5em;
`;

const Settings = (props) => {
  const [auth, authDispatch] = useContext(AuthContext);

  function cerrarSesion() {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["x-auth-token"];
    authDispatch({
      type: "AUTH",
      payload: false,
    });
    authDispatch({
      type: "USER",
      payload: {},
    });
    props.hideSettings();
  }

  return (
    <Container>
      <BoxItem>
        <TextItem>{auth.user.rol}</TextItem>
      </BoxItem>
      <Line />
      <BoxExit onClick={() => cerrarSesion()}>
        <Icon
          width={"24px"}
          height={"24px"}
          color={"#3c3c3c"}
          children={
            <path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
          }
        />
        <TextExit>Cerrar Sesión</TextExit>
      </BoxExit>
    </Container>
  );
};

export default Settings;
