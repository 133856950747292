import React, { useContext } from "react";
import styled from "styled-components";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LeakAddIcon from "@material-ui/icons/LeakAdd";
import AppsIcon from "@material-ui/icons/Apps";
import { Link } from "react-router-dom";
import { DashContext } from "../context/dashboard";
import blueGrey from "@material-ui/core/colors/blueGrey";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import { CalendarToday } from "@material-ui/icons";

const primary = blueGrey[50];

const Container = styled.div`
  grid-column: 1 / 4;
  grid-row: 2 / 11;
  background: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  @media (min-width: 300px) and (max-width: 425px) {
    grid-column: 1 / 3;
    padding: 0.5em;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },

  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state, dispatch] = useContext(DashContext);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <List>
        <Link to="/admin" style={{ color: primary }}>
          <ListItem button className={classes.colorBackground}>
            <ListItemIcon>
              <AccessibilityIcon style={{ color: primary }} />
            </ListItemIcon>
            <ListItemText
              primary="ADMINISTRACION USUARIOS"
              style={{ color: primary }}
            />
          </ListItem>
        </Link>
        <Link to="/especialista" style={{ color: primary }}>
          <ListItem
            button
            onClick={handleClick}
            className={classes.colorBackground}
          >
            <ListItemIcon>
              <TouchAppIcon style={{ color: primary }} />
            </ListItemIcon>
            <ListItemText
              primary="GESTION DE ESPECIALISTAS"
              style={{ color: primary }}
            />
          </ListItem>
        </Link>
        <Link to="/raci" style={{ color: primary }}>
          <ListItem
            button
            onClick={handleClick}
            className={classes.colorBackground}
          >
            <ListItemIcon>
              <AppsIcon style={{ color: primary }} />
            </ListItemIcon>
            <ListItemText
              primary="Gestión matriz RACI"
              style={{ color: primary }}
            />
          </ListItem>
        </Link>
        <Link to="/servicios" style={{ color: primary }}>
          <ListItem
            button
            onClick={handleClick}
            className={classes.colorBackground}
          >
            <ListItemIcon>
              <LeakAddIcon style={{ color: primary }} />
            </ListItemIcon>
            <ListItemText primary="Servicios" style={{ color: primary }} />
          </ListItem>
        </Link>
        <Link to="/schedule" style={{ color: primary }}>
          <ListItem
            button
            onClick={handleClick}
            className={classes.colorBackground}
          >
            <ListItemIcon>
              <ScheduleIcon style={{ color: primary }} />
            </ListItemIcon>
            <ListItemText
              primary="Horario de turnos"
              style={{ color: primary }}
            />
          </ListItem>
        </Link>
        <Link to="turnos" style={{ color: primary }}>
          <ListItem
            button
            onClick={handleClick}
            className={classes.colorBackground}
          >
            <ListItemIcon>
              <CalendarToday style={{ color: primary }} />
            </ListItemIcon>
            <ListItemText
              primary="Gestion de turnos"
              style={{ color: primary }}
            />
          </ListItem>
        </Link>

        <Divider />
      </List>
    </Container>
  );
};

export default Sidebar;
