import { Grid } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import Form from "./FormRelServicios";
import Lista from "./ListaServicios";
const variableEntorno = process.env.REACT_APP_API_URL;

const RelServicios = () => {
  const [data, setData] = useState([]);
  const [change, setChange] = useState(false);
  const getMatriz = async () => {
    try {
      const res = await axios.get(`${variableEntorno}api/servicios`);
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMatriz();
  }, [change]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Form change={setChange} />
        </Grid>

        <Grid item xs={8}>
          <Lista data={data} change={setChange}/>
        </Grid>
      </Grid>
    </>
  );
};

export default RelServicios;
