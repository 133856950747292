import React, { useReducer, createContext } from "react";
export const DashContext = createContext({});

let initialState = {
  refrescar: false,
  settings: false,

  showFilters: false,
  sidebar: true,
  showButtonSelect: false,
  endpoint: "",
};

let contextReducer = (state, action) => {
  switch (action.type) {
    case "REFRESCAR":
      return { ...state, refrescar: action.payload };
    case "SETTINGS":
      return { ...state, settings: action.payload };
    case "SHOW_FILTERS":
      return { ...state, showFilters: action.payload };
    case "SIDEBAR":
      return { ...state, sidebar: action.payload };
    case "SHOWBUTTONSELECT":
      return { ...state, showButtonSelect: action.payload };
    case "ENDPOINT":
      return { ...state, endpoint: action.payload };
    default:
      return initialState;
  }
};

const DashContextProvider = ({ children }) => {
  const [states, dispatch] = useReducer(contextReducer, initialState);

  return (
    <DashContext.Provider value={[states, dispatch]}>
      {children}
    </DashContext.Provider>
  );
};

export default DashContextProvider;
